<!--

      This view displays the list of recipes. It is reachable from within
      the discover page by clicking on "More".

 -->

<template>
  <Page
    :title="title"
    :scrollevents="true"
    @scrolled="scrollingHappened($event)"
  >
    <div ref="scrollarea">
      <NewCard v-if="showNewCard" icon="heartOutline">
        Du hast bisher keine Favoriten.
      </NewCard>
      <ion-skeleton-text animated class="skeleton-card" v-if="loading"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-card" v-if="loading"></ion-skeleton-text>
      <ion-skeleton-text animated class="skeleton-card" v-if="loading"></ion-skeleton-text>
      <RecipeCard
        v-for="f in visibleData" :key="f.id"
        :recipe="f"
        :showAvailability="true"
      />
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import RecipeCard from '@/components/cards/RecipeCard.vue'
import NewCard from '@/components/cards/NewCard.vue'
import { IonSkeletonText } from '@ionic/vue'


export default {
  name: 'recipelist',
  props: ['endpoint', 'title', 'page'],
  components: {
    Page,
    RecipeCard,
    IonSkeletonText,
    NewCard
  },
  data () {
    return {
      lastDataObj: null,
      visibleData: [],
      loading: null,
      loadMoreNow: null,
      paginationPage: null
    }
  },
  computed: {
    showNewCard () {
      return this.endpoint === 'favorites' && this.visibleData.length === 0 && !this.loading
    },
    orderChanged () {
      return this.$store.state.neworder.orderproducts
    },
    priceDeltas () {
      return this.$store.state.priceDeltas
    }
  },
  watch: {
    loadMoreNow () {
      if (this.loadMoreNow === true) {
        this.loadNextPage()
      }
    },
    orderChanged () {
      this.reloadEndpoint()
    },
    priceDeltas () {
      if (this.$store.state.priceDeltas) {
        for (const recipe of this.visibleData) {
          const foundPriceDelta = this.$store.state.priceDeltas.find(priceDelta => priceDelta.recipe_id === recipe.id)
          if (foundPriceDelta) recipe.price_delta = foundPriceDelta.price_delta
        }
      }
    }
  },
  methods: {
    scrollingHappened (event) {
      if (this.$refs && this.$refs.scrollarea) {
        const scrollThreshold = 2000
        const remainingScrollHeight = this.$refs.scrollarea.offsetHeight - event.detail.scrollTop
        const exceededThreshold = remainingScrollHeight < scrollThreshold
        this.loadMoreNow = exceededThreshold
      }
    },
    loadFirstPage () {
      this.loading = true
      this.loadNextPage().then(() => {
        this.loading = false
      })
    },
    reloadEndpoint () {
      const endpointsToReload = ['leftovers', 'suggested']
      if (!endpointsToReload.includes(this.endpoint)) return;

      this.visibleData = []
      this.lastDataObj = null
      this.loadFirstPage()

      this.$refs.scrollarea.parentElement.parentElement.scrollToTop()

      if (this.$store.state.profile.show_recipe_reload_explanation_popup) {
        this.$store.dispatch('openModal', { name: 'recipereloadexplanation' })
      }
    },
    async loadNextPage() {
      let url
      if (this.$store.state.replaceme) {
        url = `${this.$store.state.api.plan.weekly}${this.$store.state.newplan.id}/replace/${this.$store.state.replaceme.id}/${this.endpoint}/`
      } else {
        url = `${this.$store.state.api.plan.weekly}${this.$store.state.newplan.id}/add/${this.endpoint}/`
      }
      if (this.lastDataObj) {
        // last page; nothing to do
        if (this.lastDataObj.next === null) {
          return
        }
        url = this.lastDataObj.next
      }
      await this.$store.dispatch('apiSend', {url: url}).then(nextpage => 
        this.usePage(nextpage)
      )
    },
    usePage (nextpage, recordImpressions=true) {
      if (nextpage && nextpage.hasOwnProperty('results')) {
        this.lastDataObj = nextpage
        this.visibleData = this.visibleData.concat(nextpage.results)
        const recipeIds = nextpage.results.map(recipe => recipe.id)
        this.$store.dispatch('getRecipesAvailability', recipeIds)

        // Record recipe impression
        if (recordImpressions === true) {
          const impressions = recipeIds.map(recipeId => (
            { recipe: recipeId, flag: this.$store.state.recipeImpressionFlags.SEEN }
          ))
          this.$store.dispatch('recordRecipeImpression', impressions)
        }
      }
    }
  },
  mounted () {
    if (this.page) {
      this.usePage(JSON.parse(this.page), false)
    } else {
      this.loadFirstPage()
    }

  }
}
</script>
